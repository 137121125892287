<template>
    <List :fs="fs" api="users" apiList="users/customers" class="user-customers" editLink="/admin/users" title="Customers">
        <template slot="list-bottom" slot-scope="{items}">
            <md-table-header>
                <md-table-row>
                    <md-table-head>Total: {{items.length}}</md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>
            </md-table-header>
        </template>
    </List>
</template>
<script>
import List from "@/components/List";
export default {
    components: {
        List
    },
    computed: {
        fs() {
            return [
                { heading: "Email", data: "email", type: "readonly" },
                { heading: "Name", data: "name", type: "text" },
                { heading: "Company/Organization", data: "company", type: "text" },
                { heading: "Role", data: "role", type: "text" },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Active", data: "active", type: "boolean", action: true }
            ];
        }
    }
};
</script>
<style lang="less">
.user-customers {
}
</style>
