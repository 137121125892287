<template>
    <List :fs="fs" addLink="/admin/users/new-staff" api="users" apiList="users/staffs" class="user-staffs" editLink="/admin/users" title="Staff">
        <template slot="list-bottom" slot-scope="{ items }">
            <md-table-header>
                <md-table-row>
                    <md-table-head>Total: {{ items.length }}</md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>
            </md-table-header>
        </template>
        <template slot="list-action" slot-scope="{ item }">
            <a @click.prevent="login(item)" class="md-icon-button" href v-show="/dev/.test(env)">Login</a>
        </template>
    </List>
</template>
<script>
import List from "@/components/List";
import { mapGetters } from "vuex";

export default {
    components: {
        List
    },
    computed: {
        ...mapGetters({
            env: "env",
            hasRole: "user/hasRole"
        }),
        fs() {
            return [
                { heading: "Email", data: "email", type: "readonly", validate: "required|email" },
                { heading: "Name", data: "name", type: "text", validate: "required" },
                { heading: "Role", data: "role", type: "readonly", default: "staff" },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Vendor", data: "staff_vendor_name", type: "readonly", when: "list" },
                {
                    heading: "Permissions",
                    data: "permissions",
                    type: "checkboxs",
                    options: [
                        { heading: "Edit Product", data: "edit_product" },
                        { heading: "View Product Review", data: "view_product_review" },
                        { heading: "Edit Order", data: "edit_order" },
                        { heading: "View Order Amount", data: "view_order_amount" },
                        { heading: "View Order List Amount", data: "view_order_list_amount" },
                        { heading: "Edit Order Email", data: "edit_order_email" },
                        { heading: "Edit Order Pos Payment", data: "edit_order_payment_pos" },
                        { heading: "View Report", data: "view_report" },
                        { heading: "Reply QA", data: "reply_qa" }
                    ],
                    default: {},
                    when: "edit"
                },
                { heading: "Active", data: "active", type: "boolean", action: this.hasRole("vendor") ? true : false }
            ];
        }
    },
    methods: {
        async login(user) {
            console.info(user);
            let result = await this.$store.dispatch("crud/post", {
                api: `users/one-click-login?userId=${user._id}`
            });
            result.user.token = result.token;
            this.$store.commit("user/setUser", result.user);
            this.$store.commit("setMessage", "Login Success");
            this.$router.push("/");
        }
    }
};
</script>
<style lang="less">
.user-staffs {
    &.common-list {
        .md-table-body:nth-child(2) {
            // height: calc(~"100vh - 420px");
        }
    }
}
</style>
