<template>
    <List :fs="fs" api="users" apiList="users/admins" class="user-admins" editLink="/admin/users" title="Admins">
        <template slot="list-bottom" slot-scope="{items}">
            <md-table-header>
                <md-table-row>
                    <md-table-head>Total: {{items.length}}</md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>
            </md-table-header>
        </template>
        <template slot="list-action" slot-scope="{item}">
            <a @click.prevent="login(item)" class="md-icon-button" href v-show="/dev/.test(env)">Login</a>
        </template>
    </List>
</template>
<script>
import List from "@/components/List";
import { userRoles } from "@/config";
import { mapGetters } from "vuex";
export default {
    components: {
        List
    },

    computed: {
        ...mapGetters({
            env: "env"
        }),
        fs() {
            return [
                { heading: "Email", data: "email", type: "readonly" },
                { heading: "Name", data: "name", type: "text" },
                { heading: "Role", data: "role", type: "select", options: userRoles },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Active", data: "active", type: "boolean", action: true }
            ];
        }
    },
    methods: {
        async login(user) {
            console.info(user);
            let result = await this.$store.dispatch("crud/post", {
                api: `users/one-click-login?userId=${user._id}`
            });
            result.user.token = result.token;
            this.$store.commit("user/setUser", result.user);
            this.$store.commit("setMessage", "Login Success");
            this.$router.push("/");
        }
    }
};
</script>
<style lang="less">
.user-admins {
    &.common-list {
        // .md-table-body:nth-child(2) {
        //     height: calc(~"100vh - 420px");
        // }
    }
}
</style>
