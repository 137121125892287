<template>
    <List :fs="fs" api="users" apiList="users/vendors" class="user-vendors" editLink="/admin/users" title="Vendors">
        <template slot="list-bottom" slot-scope="{ items }">
            <md-table-header>
                <md-table-row>
                    <md-table-head>Total: {{ items.length }}</md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>
            </md-table-header>
        </template>
        <template slot="list-action" slot-scope="{ item }">
            <!-- v-show="/dev/.test(env)" -->
            <a @click.prevent="login(item)" class="md-icon-button" href>Login</a>
        </template>
        <template slot="list-search">
            <md-switch v-model="autoActive" @change="updateAutoActive">Auto Activate Vendor</md-switch>
        </template>

        <template slot="Connected" slot-scope="{ model }">
            <span>{{ getConnected(model) }}</span>
        </template>
    </List>
</template>
<script>
import List from "@/components/List";
import { mapGetters } from "vuex";
import { userRoles } from "@/config";

export default {
    components: {
        List
    },
    data() {
        return {
            amount_percent: null,
            autoActive: false
        };
    },
    computed: {
        ...mapGetters({
            env: "env",
            hasRole: "user/hasRole"
        }),
        getConnected() {
            return item => {
                let result = [];
                if (item.tilledConnected) {
                    result.push("EzTimePay");
                }
                if (item.stripeConnectId) {
                    result.push("Stripe");
                }
                return result.join(" & ");
            };
        },

        fs() {
            return [
                { heading: "Email", data: "email", type: "readonly" },
                { heading: "Name", data: "name", type: "text" },
                { heading: "Role", data: "role", type: "select", options: userRoles },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Active", data: "active", type: "boolean", action: true },
                {
                    heading: "Percent %",
                    data: "vendor.percent",
                    type: "text",
                    default: this.amount_percent
                },
                {
                    heading: "AppFee",
                    type: "link",
                    data: "appfee",
                    default: "App Fee",
                    link: `/account/vendor/appfee?id=:_id`
                },
                {
                    heading: "Connected",
                    type: "text",
                    data: "Connected"
                    // data: item => {
                    //     let result = [];
                    //     if (item.tilledConnected) {
                    //         result.push("EzTimePay");
                    //     }
                    //     if (item.stripeConnectId) {
                    //         result.push("Stripe");
                    //     }
                    //     return result.join(" & ");
                    // }
                }
            ];
        }
    },
    methods: {
        async loadPaymentSetting() {
            const setting = await this.$store.dispatch("crud/get", {
                api: "settings/payment"
            });
            this.amount_percent = setting.value.amount_percent;
        },
        async loadActiveSetting() {
            const setting = await this.$store.dispatch("crud/get", {
                api: "settings/vendor-auto-active"
            });
            this.autoActive = setting.value || false;
        },
        async updateAutoActive(val) {
            await this.$store.dispatch("crud/put", {
                api: "settings/vendor-auto-active",
                data: {
                    value: val
                }
            });
            this.$store.commit("setMessage", "Update Success");
        },
        async login(user) {
            console.info(user);
            let result = await this.$store.dispatch("crud/post", {
                api: `users/one-click-login?userId=${user._id}`
            });
            result.user.token = result.token;
            this.$store.commit("user/setUser", result.user);
            this.$store.commit("setMessage", "Login Success");
            this.$router.push("/");
        }
    },
    async created() {
        console.info(process.env.NODE_ENV);
        this.loadPaymentSetting();
        this.loadActiveSetting();
    }
};
</script>
<style lang="less">
.user-vendors {
    &.common-list {
        .md-table-body:nth-child(2) {
            // height: calc(~"100vh - 420px");
        }
    }
}
</style>
