<template>
    <List :apiList="apiList" :fs="fs" addLink="/admin/users/new-branch" api="users" class="user-branches" editLink="/admin/users" title="Branches">
        <template slot="list-bottom" slot-scope="{items}">
            <md-table-header>
                <md-table-row>
                    <md-table-head>Total: {{items.length}}</md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>
            </md-table-header>
        </template>
        <template slot="list-action" slot-scope="{item}">
            <a @click.prevent="login(item)" class="md-icon-button" href>Login</a>
        </template>
    </List>
</template>
<script>
import List from "@/components/List";
import { mapGetters } from "vuex";
export default {
    components: {
        List
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        }),
        apiList() {
            if (this.hasRole("manager")) {
                return "users/branches";
            } else {
                return `users/branches?master_vendor_id=${this.user._id}`;
            }
        },
        fs() {
            return [
                { heading: "Email", data: "email", type: "readonly", validate: "required|email" },
                { heading: "Name", data: "name", type: "text", validate: "required" },
                { heading: "Role", data: "role", type: "readonly", default: "" },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Master Vendor", data: "master_vendor.name", type: "readonly" },
                { heading: "Active", data: "active", type: "boolean", action: this.hasRole("manager") ? true : false }
            ];
        }
    },
    methods: {
        async login(user) {
            console.info(user);
            let result = await this.$store.dispatch("crud/post", {
                api: `users/one-click-login?userId=${user._id}`
            });
            result.user.token = result.token;
            this.$store.commit("user/setUser", result.user);
            this.$store.commit("setMessage", "Login Success");
            this.$router.push("/");
        }
    }
};
</script>
<style lang="less">
</style>
