<template>
    <div class="container">
        <md-tabs @change="tabChange" v-if="user">
            <md-tab :md-active="tabIndex==0" :md-disabled="!hasRole('admin')" md-label="Admins">
                <user-admins v-if="hasRole('admin') && tabIndex==0"></user-admins>
            </md-tab>
            <md-tab :md-active="tabIndex==1" :md-disabled="!hasRole('manager')" md-label="Vendors">
                <user-vendors v-if="hasRole('manager') && tabIndex==1"></user-vendors>
            </md-tab>
            <md-tab :md-active="tabIndex==2" md-label="Staff">
                <user-staffs v-if="tabIndex==2"></user-staffs>
            </md-tab>
            <md-tab :md-active="tabIndex==3" :md-disabled="!hasRole('manager')" md-label="Customers">
                <user-customers v-if="hasRole('manager') && tabIndex==3"></user-customers>
            </md-tab>
            <md-tab :md-active="tabIndex==4" :md-disabled="!hasRole('vendor') || true" md-label="Branches">
                <user-branches v-if="hasRole('vendor') && tabIndex==4"></user-branches>
            </md-tab>
            <md-tab :md-active="tabIndex==5" :md-disabled="!hasRole('vendor') || tabIndex!=5" md-label="Add Staff">
                <user-edit mode="staff" v-if="hasRole('vendor') && tabIndex==5"></user-edit>
            </md-tab>
            <md-tab :md-active="tabIndex==6" :md-disabled="!hasRole('vendor') || user.master_vendor_id || true" md-label="Add Branch">
                <user-edit mode="branch" v-if="hasRole('vendor') && tabIndex==6"></user-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import List from "@/components/List";
import UserAdmins from "@/components/User/UserAdmins";
import UserVendors from "@/components/User/UserVendors";
import UserStaffs from "@/components/User/UserStaffs";
import UserCustomers from "@/components/User/UserCustomers";
import UserBranches from "@/components/User/UserBranches";
import UserEdit from "@/components/User/UserEdit";

export default {
    props: ["id"],

    components: {
        UserAdmins,
        UserVendors,
        UserStaffs,
        UserCustomers,
        UserBranches,
        UserEdit
    },

    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.id == "admins") {
                    this.tabIndex = 0;
                } else if (this.id == "vendors") {
                    this.tabIndex = 1;
                } else if (this.id == "staffs") {
                    this.tabIndex = 2;
                } else if (this.id == "customers") {
                    this.tabIndex = 3;
                } else if (this.id == "branches") {
                    this.tabIndex = 4;
                } else if (this.id == "new-staff") {
                    this.tabIndex = 5;
                } else if (this.id == "new-branch") {
                    this.tabIndex = 6;
                }
                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }
            if (index == 0) {
                this.$router.push("/admin/users/admins");
            }
            if (index == 1) {
                this.$router.push("/admin/users/vendors");
            }
            if (index == 2) {
                this.$router.push(`/admin/users/staffs`);
            }
            if (index == 3) {
                this.$router.push(`/admin/users/customers`);
            }
            if (index == 4) {
                this.$router.push(`/admin/users/branches`);
            }
            if (index == 5) {
                this.$router.push(`/admin/users/new-staff`);
            }
            if (index == 6) {
                this.$router.push(`/admin/users/new-branch`);
            }
        }
    }
};
</script>